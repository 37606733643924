<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab
              :title="$t('administration.products.tabs.data.label')"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="item.title"
                      :state="errors && errors.title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.title">
                      {{ errors.title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.role.label')"
                    label-for="role_id"
                    :state="errors && errors.role_id ? false : null"
                  >
                    <v-select
                      id="role_id"
                      v-model="item.role_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="rolesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="role_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.role_id">
                      {{ errors.role_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  :class="`hidden`"
                >
                  <b-form-group
                    :label="`Dynamic feed URL - UK`"
                    label-for="dynamic_url"
                  >
                    <b-form-input
                      id="dynamic_url"
                      v-model="dynamicUrlUk"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="`Static feed URL - UK`"
                    label-for="static_url"
                  >
                    <b-form-input
                      id="static_url"
                      v-model="staticUrlUk"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  :class="`hidden`"
                >
                  <b-form-group
                    :label="`Dynamic feed URL - RU`"
                    label-for="dynamic_url"
                  >
                    <b-form-input
                      id="dynamic_url_ru"
                      v-model="dynamicUrlRu"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="`Static feed URL - RU`"
                    label-for="static_url"
                  >
                    <b-form-input
                      id="static_url_ru"
                      v-model="staticUrlRu"
                      readonly
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab :title="$t('general.seo_template_types.filter')">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.zero_qnt.label')"
                    label-for="zero_qnt"
                    :state="errors && errors.zero_qnt ? false : null"
                  >
                    <v-select
                      id="zero_qnt"
                      v-model="item.zero_qnt"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="zero_qnt"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.zero_qnt">
                      {{ errors.zero_qnt[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.only_discount.label')"
                    label-for="only_discount"
                    :state="errors && errors.only_discount ? false : null"
                  >
                    <v-select
                      id="only_discount"
                      v-model="item.only_discount"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="only_discount"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.only_discount">
                      {{ errors.only_discount[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.zero_img.label')"
                    label-for="zero_img"
                    :state="errors && errors.zero_img ? false : null"
                  >
                    <v-select
                      id="zero_img"
                      v-model="item.zero_img"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="zero_img"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.zero_img">
                      {{ errors.zero_img[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <br>
              <b-row v-if="errors && errors.stocks">
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.stocks[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row v-if="errors && errors.categories">
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.categories[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row v-if="errors && errors.attributes">
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-tabs>
                <b-tab :title="$t('form.stocks.label')">
                  <div class="check_wrap">
                    <div class="checks">
                      <b-form-checkbox
                        v-for="st in stocksOptions"
                        :key="st.value"
                        v-model="item.stocks"
                        :value="st.value"
                        class="custom-control-primary"
                      >
                        {{ st.label }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-tab>
                <b-tab :title="$t('form.categories.label')">
                  <div class="check_wrap">
                    <div class="checks">
                      <b-form-checkbox
                        v-for="co in categoriesOptions"
                        :key="co.value"
                        v-model="item.categories"
                        :value="co.value"
                        class="custom-control-primary"
                      >
                        {{ co.label }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-tab>
                <b-tab :title="$t('administration.products.tabs.attributes.label')">
                  <div
                    v-for="ao in attributesOptions"
                    :key="ao.value"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        {{ ao.label }}
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <div class="check_wrap mh_200">
                          <div class="checks">
                            <b-form-checkbox
                              v-for="avo in attributeValuesOptions[`attribute_${ao.value}`]"
                              :key="avo.value"
                              v-model="item.attributes"
                              :value="avo.value"
                              class="custom-control-primary"
                            >
                              {{ avo.label }}
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <hr>
                  </div>
                </b-tab>

              </b-tabs>
              <br>
              <br>
            </b-tab>

            <b-tab
              :title="$t('form.template.label')"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.template.label')"
                    label-for="template"
                  >
                    <b-form-textarea
                      id="template"
                      v-model="item.template"
                      :state="errors && errors.template ? false : null"
                      :rows="`30`"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.template">
                      {{ errors.template[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <br>
              <br>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'feeds-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BFormInvalidFeedback,
  BImg,
  BOverlay,
  BFormTextarea,
  BLink,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import StatusMixin from '@/mixins/StatusMixin'
import _ from 'lodash'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormTextarea,
    BFormInvalidFeedback,
    vSelect,
    BOverlay,
    BFormCheckbox,
    Treeselect,
  },
  mixins: [StatusMixin, CategoryMixin],
  data() {
    return {
      item: null,
      rolesOptions: [],
      categoriesOptions: [],
      attributesOptions: [],
      attributeValuesOptions: [],
      stocksOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
    staticUrlUk() {
      return this.item.slug ? `${process.env.VUE_APP_API_URL}/feed/${this.item.slug}_uk.xml` : ''
    },
    dynamicUrlUk() {
      return this.item.slug ? `${process.env.VUE_APP_API_URL}/api/promo/feeds/${this.item.slug}?locale=uk` : ''
    },
    staticUrlRu() {
      return this.item.slug ? `${process.env.VUE_APP_API_URL}/feed/${this.item.slug}_ru.xml` : ''
    },
    dynamicUrlRu() {
      return this.item.slug ? `${process.env.VUE_APP_API_URL}/api/promo/feeds/${this.item.slug}?locale=ru` : ''
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/roles', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.rolesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get(`/api/administration/feeds/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'feeds-index' })
        }
      })
  },
  created() {
    this.loadDataTab()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    loadDataTab() {
      if (!Object.keys(this.stocksOptions).length) {
        this.$http.get('/api/stocks', { params: { status: 'enabled', per_page: 100 } })
          .then(response => {
            this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          })
      }

      if (!Object.keys(this.categoriesOptions).length) {
        this.$http.get('/api/administration/fullcategories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            // this.categoriesOptions = response.data
            this.categoriesOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
          })
      }

      if (!Object.keys(this.attributesOptions).length) {
        this.$http.get('/api/attributes', {
          params: {
            sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 200, relations: 'values.translations',
          },
        })
          .then(response => {
            this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')

            const vm = this

            _.each(response.data.data, item => {
              vm.attributeValuesOptions[`attribute_${item.id}`] = vm.$options.filters.transformForVSelect(item.values, 'id', 'title')
            })
          })
      }
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        status: this.item.status,
        zero_qnt: this.item.zero_qnt,
        zero_img: this.item.zero_img,
        only_discount: this.item.only_discount,
        template: this.item.template,
        role_id: this.item.role_id,
        categories: this.item.categories,
        stocks: this.item.stocks,
        attributes: this.item.attributes,
      }

      this.$http.put(`/api/administration/feeds/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'feeds-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        zero_qnt: item.zero_qnt,
        zero_img: item.zero_img,
        only_discount: item.only_discount,
        title: item.title,
        template: item.template,
        slug: item.slug,
        role_id: item.role_id,
        categories: [],
        stocks: [],
        attributes: [],
      }

      data.attributes = _.reduce(item.attributes, (result, attribute) => {
        result.push(attribute.attribute_value_id)
        return result
      }, [])

      data.categories = _.reduce(item.categories, (result, category) => {
        result.push(category.category_id)
        return result
      }, [])

      data.stocks = _.reduce(item.stocks, (result, stock) => {
        result.push(stock.stock_id)
        return result
      }, [])

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.custom-checkbox {
  margin:0 0 15px;
}

.mh_200 {
  max-height: 190px;
  overflow: scroll;
}
</style>
